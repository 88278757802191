body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Ant-desgin components */
/* in your CSS/SCSS file */
.right-aligned-menu-items {
  display: flex;
  justify-content: flex-end; /* Align menu items to the right */
  background-color: #27272A; /* Set the background color of the menu bar */
  padding-left: 3%;
  font-size: 18px;
}


.right-aligned-menu-items .ant-menu-item-selected,
.right-aligned-menu-items .ant-menu-item-selected a {
  color: white !important;
  background-color: #27272A !important;
}


.centered-menu {
  display: flex;
  justify-content: center;
  font-size: 20px;

}

/* To center the text of each menu item, if needed */
.ant-menu-horizontal > .ant-menu-item {
  text-align: center;
}

.custom-collapse {
  display: flex;
  justify-content: center;
  width: 100%;
}

.custom-collapse .ant-collapse-header {
  font-size: 1.5em;
  text-align: center;
  width: 100%; 
  display: flex; 
  justify-content: center;
  align-items: center;
}

.custom-collapse .ant-collapse-content {
  text-align: center;
  width: 100%;
}

.custom-collapse .ant-collapse-item {
  width: 95%;
  margin: 0 auto;
}

.customDashedButton {
  border-color: black !important;
}

.customDashedButton:hover {
  border-color: #26756B !important;
}

.citation-block {
  padding: 20px;
  margin-top: 20px;
  background-color: #f4f4f4;
  border-radius: 5px;
  h2 {
      margin-bottom: 10px;
  }
  pre {
      background: #e0e0e0;
      padding: 10px;
      border-radius: 5px;
  }
}

.conversation-card {
  border-color: #333; /* Default border color */
  border-width: 2.5px; /* Default border width */
  border-style: solid; /* Ensure the border is solid */
  transition: border-color 0.3s ease;
  cursor: pointer;
}

.conversation-card-danger:hover {
  border-color: #ff4081; /* Lighter or different shade for hover effect */
}

.conversation-card-safe:hover {
  border-color: #4a90e2; /* Lighter or different shade for hover effect */
}

.method-card-style .ant-card-head {
  border-bottom: 2px solid #111;
}

.method-card-style .ant-card-body {
  background-color: transparent; /* Make sure the gradient shows through */
}


.ant-select-selector, .ant-select-selector {
  border: 1px solid #111 !important;
}

.ant-select-selector:hover, .ant-select-selector:focus-within {
  border: 2.5px solid #111 !important;
}

.custom-radio-group .ant-radio-button-wrapper.radio-all {
  color: #333;
  border-color: #333;
}
.custom-radio-group .ant-radio-button-wrapper.radio-all:hover {
  color: white;
  background-color: #333;
}
.custom-radio-group .ant-radio-button-wrapper.radio-positive {
  color: #c00050;
  border-color: #c00050;
}
.custom-radio-group .ant-radio-button-wrapper.radio-positive:hover {
  color: white;
  background-color: #c00050;
}
.custom-radio-group .ant-radio-button-wrapper.radio-negative {
  color: #0866FF;
  border-color: #0866FF;
}
.custom-radio-group .ant-radio-button-wrapper.radio-negative:hover {
  color: white;
  background-color: #0866FF;
}
.custom-radio-group .ant-radio-button-wrapper-checked.radio-all {
  color: white !important;
  background-color: #333 !important;
  border-color: #333 !important;
}
.custom-radio-group .ant-radio-button-wrapper-checked.radio-positive {
  color: white !important;
  background-color: #c00050 !important;
  border-color: #c00050 !important;
}
.custom-radio-group .ant-radio-button-wrapper-checked.radio-negative {
  color: white !important;
  background-color: #0866FF !important;
  border-color: #0866FF !important;
}

.custom-pagination .ant-pagination-item,
.custom-pagination .ant-pagination-prev,
.custom-pagination .ant-pagination-next,
.custom-pagination .ant-pagination-jump-prev,
.custom-pagination .ant-pagination-jump-next {
    border-color: black;
}

.custom-pagination .ant-pagination-item a,
.custom-pagination .ant-pagination-prev a,
.custom-pagination .ant-pagination-next a,
.custom-pagination .ant-pagination-jump-prev a,
.custom-pagination .ant-pagination-jump-next a {
    color: black;
}

.custom-pagination .ant-pagination-item-active {
    background-color: black;
    border-color: black;
}

.custom-pagination .ant-pagination-item-active a {
    color: white;
}

.custom-pagination .ant-pagination-item-active:hover a {
  color: white !important;
}

.custom-pagination .ant-pagination-item:hover,
.custom-pagination .ant-pagination-prev:hover,
.custom-pagination .ant-pagination-next:hover,
.custom-pagination .ant-pagination-item-link:hover {
    border-color: black;
    color: black;
}

.custom-pagination .ant-pagination-item:hover a,
.custom-pagination .ant-pagination-prev:hover a,
.custom-pagination .ant-pagination-next:hover a {
    color: black;
}

